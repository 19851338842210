import React, { useContext, useEffect, useState } from 'react';
import feeds from '../../utils/feeds';
import { SettingsContext } from '../../contexts/settings-provider';
import Board from '../board/board';
import { db as firebaseDb } from '../../utils/firebase';

const Game = () => {
  const [startLength, setStartLength] = useState(3);
  const [settings, dispatch] = useContext(SettingsContext);

  const [headlines, setHeadlines] = useState([]);

  const [correctWord, setCorrectWord] = useState([]);
  const [allWords, setAllWords] = useState([]);
  const [currentWord, setCurrentWord] = useState([]);

  const [attempts, setAttempts] = useState(0);
  const [solvedStatus, setSolvedStatus] = useState(false);

  const [solvedHeadlines, setSolvedHeadlines] = useState([]);

  useEffect(() => {
    let progress = 0;
    feeds.forEach((feed, i) => {
      fetch(`https://warm-atoll-15307.herokuapp.com/${feed.link}`)
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
        .then((data) => {
          progress++;
          const items = data.querySelectorAll('item');
          items.forEach((item) => {
            const link = item.querySelector('link').innerHTML;
            const description = item
              .querySelector('description')
              ?.innerHTML.replace('<![CDATA[', '')
              .replace(']]>', '');
            const title = item
              .querySelector('title')
              .innerHTML.replace('<![CDATA[', '')
              .replace(']]>', '')
              .replace(' ', ' ')
              .replace('-', '')
              .replace('–', '')
              .replace('.', '')
              .replace(',', '')
              .replace(':', ': ')
              .replace('  ', ' ')
              .replace('&amp;', '')
              .replace('amp;', '')
              .replace('&nbsp;', '')
              .replace('nbsp;', '')

              .split(' ');

            let filteredTitle = title.filter(function (word) {
              return word.length !== 0;
            });

            if (filteredTitle.length >= startLength) {
              const newHeadlines = headlines;
              newHeadlines.push({
                outlet: { name: feed.title, logo: feed.logo },
                link: link,
                title: filteredTitle,
                description: description,
              });
              setHeadlines(newHeadlines);
            }
          });
          if (progress === feeds.length) {
            dispatch({
              type: 'update_settings',
              payload: { key: 'loading', value: false },
            });
          }
        });
    });
  }, []);

  useEffect(() => {
    if (settings.loading === false && settings.summary === false) {
      const headline = getRandomHeadline();
      const extraWords = getRandomWords(headline.title.length);
      const allWords = shuffle(headline.title.concat(extraWords));

      const newSolvedHeadlines = solvedHeadlines;
      newSolvedHeadlines.push(headline.link);
      setSolvedHeadlines(newSolvedHeadlines);

      const descriptionLength = 140;
      let filteredDescription = headline.description;
      if (filteredDescription.length > descriptionLength) {
        filteredDescription = filteredDescription.substring(
          0,
          descriptionLength
        );
        filteredDescription += '...';
      }

      setAttempts(0);
      setCurrentWord([]);
      setCorrectWord(headline.title);
      setAllWords(allWords);

      dispatch({
        type: 'update_settings',
        payload: {
          key: 'outlet',
          value: {
            name: headline.outlet.name,
            logo: headline.outlet.logo,
            link: headline.link,
            description: filteredDescription,
          },
        },
      });

      dispatch({
        type: 'update_settings',
        payload: {
          key: 'startTime',
          value: new Date(),
        },
      });
    }
  }, [settings.loading, settings.summary]);

  const getRandomHeadline = () => {
    let searchAttempts = 0;
    let filteredHeadlines = [];
    const filterHeadlines = () => {
      return headlines.filter((headline) => {
        return (
          headline.title.length <=
            startLength + settings.score / 2 + searchAttempts &&
          solvedHeadlines.includes(headline.link) === false
        );
      });
    };
    while (filteredHeadlines.length === 0 && attempts < 25) {
      filteredHeadlines = filterHeadlines();
      searchAttempts += 0.5;
    }
    if (filteredHeadlines.length === 0) {
      setSolvedHeadlines([]);
      filteredHeadlines = filterHeadlines();
    }
    return filteredHeadlines[
      Math.floor(Math.random() * filteredHeadlines.length)
    ];
  };

  const getRandomWords = (words) => {
    const randomWords = [];
    while (randomWords.length < words) {
      const randomHeadline =
        headlines[Math.floor(Math.random() * headlines.length)].title;
      const randomWord =
        randomHeadline[Math.floor(Math.random() * randomHeadline.length)];
      if (randomWords.includes(randomWord) === false) {
        randomWords.push(randomWord);
      }
    }
    return randomWords;
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    if (
      currentWord.length > 0 &&
      currentWord.join('') === correctWord.join('') &&
      solvedStatus === false &&
      settings.summary === false &&
      settings.loading === false
    ) {
      endRound(correctWord.length - startLength + 1);
    }
  }, [correctWord, currentWord, settings.loading, attempts]);

  const endRound = (points) => {
    dispatch({
      type: 'update_settings',
      payload: { key: 'headline', value: correctWord.join(' ') },
    });

    dispatch({
      type: 'update_settings',
      payload: {
        key: 'endTime',
        value: new Date(),
      },
    });

    if (points > 0) {
      setSolvedStatus('win');
      sendTimestamp();
      setStartLength(3);
      dispatch({
        type: 'update_settings',
        payload: {
          key: 'streak',
          value: settings.streak + 1,
        },
      });
    } else {
      setSolvedStatus('loss');
      dispatch({
        type: 'update_settings',
        payload: {
          key: 'streak',
          value: 0,
        },
      });
    }

    dispatch({
      type: 'update_settings',
      payload: { key: 'score', value: settings.score + points },
    });

    setTimeout(function () {
      dispatch({
        type: 'update_settings',
        payload: { key: 'summary', value: true },
      });
      setSolvedStatus(false);
    }, 1000);
  };

  const giveUp = () => {
    endRound(-1);
  };

  const sendTimestamp = () => {
    const articleKey = correctWord.join('-').toLocaleLowerCase();
    dispatch({
      type: 'update_settings',
      payload: {
        key: 'articleKey',
        value: articleKey,
      },
    });

    const timeSpent = Math.round((new Date() - settings.startTime) / 1000);
    return firebaseDb
      .ref(`articles/${articleKey}/times/${settings.uid}`)
      .set(timeSpent, (error) => {
        if (error) {
          console.error('error', error);
        }
      });
  };

  const deleteAllData = () => {
    if (
      window.confirm(
        'Er du sikker på at du vil slette alle dataene dine? Dette kan ikke reverseres.'
      )
    ) {
      dispatch({
        type: 'reset_settings',
      });
      window.location.reload();
    }
  };

  return (
    <div>
      <Board
        correctWord={correctWord}
        allWords={allWords}
        currentWord={currentWord}
        setCurrentWord={setCurrentWord}
        blur={settings.summary || !settings.completedTutorial}
        giveUp={giveUp}
        attempts={attempts}
        setAttempts={setAttempts}
        solvedStatus={solvedStatus}
        deleteAllData={deleteAllData}
      />
    </div>
  );
};

export default Game;
