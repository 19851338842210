import React, { useContext, useEffect, useRef } from 'react';
import Loading from './components/loading/loading';
import Game from './components/game/game';
import Summary from './components/summary/summary';
import Tutorial from './components/tutorial/tutorial';
import Warning from './components/warning/warning';
import { Helmet } from 'react-helmet';
import image from './assets/some.png';
import ReactGA from 'react-ga';
import { SettingsContext } from './contexts/settings-provider';
import whatInput from 'what-input';
import { auth as firebaseAuth } from './utils/firebase';
import useAuthState from './hooks/useAuthState';

function App() {
  const [settings, dispatch] = useContext(SettingsContext);
  const mainRef = useRef(null);
  const { user } = useAuthState(firebaseAuth);

  useEffect(() => {
    if (user === null) {
      firebaseAuth.signInAnonymously().catch(function (error) {
        console.error(`Noe gikk galt: ${error}`);
        firebaseAuth
          .signInAnonymously()
          .then(() => {
            dispatch({
              type: 'update_settings',
              payload: { key: 'uid', value: user.uid },
            });
          })
          .catch((error) => {
            console.error('error', error.code, error.Message);
          });
      });
    }
    if (user) {
      dispatch({
        type: 'update_settings',
        payload: { key: 'uid', value: user.uid },
      });
    }
  }, [user, dispatch]);

  useEffect(() => {
    const checkWarning = () => {
      if (whatInput.ask() === 'touch' && settings.usingTouch === false) {
        dispatch({
          type: 'update_settings',
          payload: { key: 'usingTouch', value: true },
        });
      } else if (settings.usingTouch === true) {
        dispatch({
          type: 'update_settings',
          payload: { key: 'usingTouch', value: false },
        });
      }
    };

    window.addEventListener('pointerdown', checkWarning);
    return () => {
      window.removeEventListener('pointerdown', checkWarning);
    };
  }, [settings, dispatch]);

  const gaId = 'G-L4F7L6T97R';
  ReactGA.initialize(gaId);

  const title = 'DagensDesk';
  const description = 'Gjett dagens overskrifter';

  return (
    <main ref={mainRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="description" content={description} />
        <meta name="og:type" content={'page'} />
        <meta name="og:image" content={image} />
        <meta name="og:url" content={`https://dagensdesk.no`} />
      </Helmet>
      <Loading />
      <Game />
      <Summary />
      <Tutorial />
      <Warning />
    </main>
  );
}

export default App;
