import { useEffect } from 'react';
import useLoadingValue from './useLoadingValue';

export default (auth) => {
  const { loading, setValue, value } = useLoadingValue();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setValue);

    return () => {
      unsubscribe();
    };
  }, [auth]);

  return {
    initialising: loading,
    user: value,
  };
};
