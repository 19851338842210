import aftenposten from '../assets/logos/aftenposten.png';
import nrk from '../assets/logos/nrk.svg';
import dagbladet from '../assets/logos/dagbladet.png';
import nettavisen from '../assets/logos/nettavisen.svg';
import vg from '../assets/logos/vg.png';

const feeds = [
  {
    title: 'Aftenposten',
    logo: aftenposten,
    link: 'https://www.aftenposten.no/rss/',
  },
  {
    title: 'Nettavisen',
    logo: nettavisen,
    link: 'https://www.nettavisen.no/service/rich-rss',
  },
  {
    title: 'Dagbladet',
    logo: dagbladet,
    link: 'https://www.dagbladet.no/?lab_viewport=rss',
  },
  {
    title: 'NRK',
    logo: nrk,
    link: 'https://www.nrk.no/nyheter/siste.rss',
  },
  {
    title: 'VG',
    logo: vg,
    link: 'https://www.vg.no/rss/feed?categories=1069%2C1070',
  },
];

export default feeds;
