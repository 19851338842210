import React, { useContext } from 'react';
import styles from './warning.module.scss';
import classNames from 'classnames';
import { SettingsContext } from '../../contexts/settings-provider';

const Warning = () => {
  const [settings] = useContext(SettingsContext);

  return (
    <div
      className={classNames(styles.result, {
        [styles.active]: settings.usingTouch === true,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1>Dette spillet fungerer ikke på touch devicer</h1>
          <p>Kom tilbake på en datamaskin for å spille spillet.</p>
        </div>
      </div>
    </div>
  );
};

export default Warning;
