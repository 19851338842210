/* eslint-disable no-case-declarations */
import { db as firebaseDb } from '../../utils/firebase';

const getTimeStamp = () => {
  const time = new Date();
  return `${time.getDate()}-${time.getMonth()}-${time.getFullYear()}`;
};

const defaultSettings = {
  usingTouch: false,
  completedTutorial: false,
  loading: true,
  summary: false,
  streak: 0,
  score: 0,
  oldScore: 0,
  highScore: 0,
  headline: '',
  startTime: 0,
  endTime: 0,
  outlet: {
    name: '',
    logo: '',
    link: '',
    description: '',
  },
  timestamp: getTimeStamp(),
  version: 2.6,
  uid: null,
  articleKey: '',
};

const localStorageKey = 'currentStatus';

export const reducer = (settings, action) => {
  switch (action.type) {
    case 'update_settings':
      const updatedSettings = {
        ...settings,
        [action.payload.key]: action.payload.value,
      };
      if (updatedSettings.score > updatedSettings.highScore) {
        updatedSettings.highScore = updatedSettings.score;
        firebaseDb
          .ref(`highscores/${settings.uid}`)
          .set(updatedSettings.highScore, (error) => {
            if (error) {
              console.error('error', error);
            }
          });
      }
      if (settings.score !== updatedSettings.score) {
        updatedSettings.oldScore = settings.score;
      }
      if (updatedSettings.score < 0 || getTimeStamp() !== settings.timestamp) {
        updatedSettings.score = 0;
        updatedSettings.streak = 0;
        updatedSettings.timestamp = getTimeStamp();
      }
      localStorage.setItem(localStorageKey, JSON.stringify(updatedSettings));
      return updatedSettings;
    case 'reset_settings':
      localStorage.setItem(localStorageKey, JSON.stringify(defaultSettings));
      return defaultSettings;
    default:
      return settings;
  }
};

export const initialState = () => {
  const currentSetings = localStorage.getItem(localStorageKey);
  if (currentSetings) {
    const savedSettings = JSON.parse(currentSetings);
    if (defaultSettings.version === savedSettings.version) {
      savedSettings.loading = true;
      savedSettings.summary = false;
      savedSettings.usingTouch = false;
      savedSettings.headline = '';
      savedSettings.uid = null;
      if (getTimeStamp() !== savedSettings.timestamp) {
        savedSettings.score = 0;
        savedSettings.streak = 0;
        savedSettings.timestamp = getTimeStamp();
      }
      localStorage.setItem(localStorageKey, JSON.stringify(savedSettings));
      return savedSettings;
    } else {
      defaultSettings.highScore = savedSettings.highScore;
      if (getTimeStamp() !== savedSettings.timestamp) {
        defaultSettings.score = 0;
      } else {
        defaultSettings.score = savedSettings.score;
      }
    }
  }
  return defaultSettings;
};
