import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from 'react';
import styles from './board.module.scss';
import classNames from 'classnames';
import Button from '../button/button';
import formatTime from '../../utils/formatNumber';
import { SettingsContext } from '../../contexts/settings-provider';

const Board = ({
  allWords,
  correctWord,
  currentWord,
  setCurrentWord,
  blur,
  giveUp,
  attempts,
  setAttempts,
  solvedStatus,
  deleteAllData,
}) => {
  const [settings] = useContext(SettingsContext);

  const [rotations, setRotations] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [activeWord, setActiveWord] = useState('');
  const [activeWordIndex, setActiveWordIndex] = useState(false);
  const [activeInput, setActiveInput] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');

  const gameRef = useRef(null);

  useEffect(() => {
    const randomRotations = allWords.map(() => {
      return Math.random() * 5 - 2.5;
    });
    setRotations(randomRotations);
  }, [allWords]);

  useLayoutEffect(() => {
    const game = gameRef.current;
    game.addEventListener(
      'touchmove',
      (event) => {
        event.preventDefault();
      },
      false
    );
    return () => {
      game.removeEventListener(
        'touchmove',
        (event) => {
          event.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    if (dragCounter === 0) {
      setActiveInput(false);
    }
  }, [dragCounter]);

  const startHandler = (word, i) => {
    setActiveWord(word);
    setIsDragging(true);
    setActiveWordIndex(i);
  };

  const abortHandler = () => {
    setIsDragging(false);
    setActiveWord('');
    setActiveInput(false);
    setDragCounter(0);
  };

  const dropHandler = (e, index) => {
    e.preventDefault();
    const newCurrentWord = currentWord;
    newCurrentWord[index] = activeWord;
    setCurrentWord(newCurrentWord);
    setAttempts(attempts + 1);
    abortHandler();
  };

  useEffect(() => {
    const speed = 1000;
    const clock = window.setInterval(() => {
      const timeStamp = formatTime((new Date() - settings.startTime) / speed);
      setCurrentTime(timeStamp);
    }, speed);
    return () => {
      clearInterval(clock);
      setCurrentTime('00:00');
    };
  }, [settings.startTime]);

  const clearInputs = () => {
    setCurrentWord([]);
    setAttempts(attempts + 1);
  };

  return (
    <div
      ref={gameRef}
      className={classNames(
        styles.game,
        {
          [styles.blur]: blur === true,
        },
        {
          [styles.dragging]: isDragging === true,
        }
      )}
    >
      <div className={styles.clock}>{currentTime}</div>
      <div className={styles.bank}>
        {allWords.map((word, i) => (
          <div
            key={`${word}-${i}`}
            className={classNames(styles.word, {
              [styles.dragging]: activeWordIndex === i && isDragging === true,
            })}
            style={{ transform: `rotate(${rotations[i]}deg)` }}
            draggable={true}
            onDragStart={() => {
              startHandler(word, i);
            }}
            onDragEnd={(e) => {
              abortHandler();
            }}
          >
            {word}
          </div>
        ))}
      </div>
      <div className={styles.inputs}>
        {correctWord.map((word, i) => (
          <div
            key={`input-${i}`}
            className={classNames(styles.input, {
              [styles.over]: activeInput === i,
            })}
            onDrop={(e) => {
              dropHandler(e, i);
              setActiveInput(false);
            }}
            onDragEnter={() => {
              setActiveInput(i);
              setDragCounter(dragCounter + 1);
            }}
            onDragLeave={() => {
              setDragCounter(dragCounter - 1);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <div className={styles.marker}>
              <Icon />
            </div>
            {currentWord[i] && (
              <div
                className={classNames(styles.inputWord)}
                style={{ transform: `rotate(${rotations[i]}deg)` }}
                draggable={true}
                onDragStart={() => {
                  startHandler(currentWord[i], false);
                }}
                onDragEnd={() => {
                  const newCurrentWord = currentWord;
                  newCurrentWord[i] = false;
                  setCurrentWord(newCurrentWord);
                  setAttempts(attempts + 1);
                  abortHandler();
                }}
              >
                {currentWord[i]}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className={styles.skip}>
        <button onClick={clearInputs} className={styles.clearButton}>
          Fjern alle
        </button>
        <Button onClick={giveUp} secondary small>
          Gi opp
        </Button>
      </div>

      <div
        className={classNames(styles.status, {
          [styles.active]: solvedStatus !== false,
        })}
      >
        {solvedStatus === 'win' && (
          <div className={classNames(styles.icon, styles.win)}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          </div>
        )}
        {solvedStatus === 'loss' && (
          <div className={classNames(styles.icon, styles.loss)}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
            </svg>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <button className={styles.delete} onClick={deleteAllData}>
          Slett alle data
        </button>
        <span>
          Laget av{' '}
          <a
            href={'https://www.linkedin.com/in/alexander-solbakken/'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Alexander Solbakken @ TRY Apt
          </a>
        </span>
      </div>
    </div>
  );
};

const Icon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none">
      <rect x="13" width="8" height="33" rx="4" fill="#C4C4C4" />
      <rect
        x="33"
        y="13"
        width="8"
        height="33"
        rx="4"
        transform="rotate(90 33 13)"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default Board;
