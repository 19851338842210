import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyAlKDiPWdQ1hWQEaStA2pCA9x9JiCglohQ',
  authDomain: 'dagensdesk-948f5.firebaseapp.com',
  databaseURL:
    'https://dagensdesk-948f5-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dagensdesk-948f5',
  storageBucket: 'dagensdesk-948f5.appspot.com',
  messagingSenderId: '655288272814',
  appId: '1:655288272814:web:693653d75434ec91bf2a3f',
  measurementId: 'G-R1M019RFE8',
});

export const auth = firebase.auth();
export const db = firebase.database();

auth.languageCode = 'nb';

export default app;
