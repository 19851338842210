import React, { useContext, useState, useEffect } from 'react';
import styles from './summary.module.scss';
import classNames from 'classnames';
import { SettingsContext } from '../../contexts/settings-provider';
import Button from '../button/button';
import { db as firebaseDb } from '../../utils/firebase';
import formatTime from '../../utils/formatNumber';
import CountUp from 'react-countup';

const Summary = () => {
  const [settings, dispatch] = useContext(SettingsContext);
  const [averageTime, setAverageTime] = useState(0);
  const [highscorePosition, setHighscorePosition] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [timeUntilMidnight, setTimeUntilMidnight] = useState(0);

  const closeSummary = () => {
    dispatch({
      type: 'update_settings',
      payload: { key: 'summary', value: false },
    });
  };

  useEffect(() => {
    if (settings.summary) {
      const databaseRef = firebaseDb.ref(
        `articles/${settings.articleKey}/times`
      );
      databaseRef.on('value', (snapshot) => {
        const data = snapshot.val();
        let total = 0;
        let entries = 0;
        for (const key in data) {
          total += data[key];
          entries++;
        }
        setAverageTime(total / entries);
      });
      return () => {
        databaseRef.off();
      };
    }
  }, [settings.articleKey, settings.summary]);

  useEffect(() => {
    if (settings.summary) {
      const databaseRef = firebaseDb.ref(`highscores`).orderByValue();
      databaseRef.on('value', (snapshot) => {
        const data = snapshot.val();
        let players = 0;
        let position = 1;
        for (const key in data) {
          if (data[key] > settings.highScore) {
            position++;
          }
          players++;
        }
        setHighscorePosition(position);
        setTotalPlayers(players);
      });
      return () => {
        databaseRef.off();
      };
    }
  }, [settings.highScore, settings.summary]);

  const getSecondsUntilMidnight = () => {
    var d = new Date();
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;
  };

  useEffect(() => {
    let clock;
    if (settings.summary) {
      clock = setInterval(() => {
        const newTimeUntilMidnight = formatTime(getSecondsUntilMidnight());
        setTimeUntilMidnight(newTimeUntilMidnight);
      }, 1000);
    }
    return () => {
      clearInterval(clock);
    };
  }, [settings.highScore, settings.summary]);

  return (
    <div
      className={classNames(styles.result, {
        [styles.active]: settings.summary === true,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.meta}>
          <div className={styles.ranking}>
            <div className={styles.stat}>
              <div className={styles.name}>Highscore</div>
              <div className={styles.value}>{settings.highScore}</div>
            </div>
            {highscorePosition > 0 && (
              <div className={styles.stat}>
                <div className={styles.name}>Rangering</div>
                <div className={styles.value}>
                  {highscorePosition}/{totalPlayers}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.article}>
          <div className={styles.content}>
            <img
              className={styles.logo}
              src={settings.outlet.logo}
              alt={settings.outlet.name}
            />
            <div className={styles.title}>{settings.headline}</div>
            {settings.outlet.description && (
              <p className={styles.description}>
                {settings.outlet.description}
              </p>
            )}
          </div>
        </div>

        <div className={styles.meta}>
          <div className={styles.times}>
            <div className={styles.time}>
              <div className={styles.label}>Din tid</div>
              <div className={styles.value}>
                {formatTime((settings.endTime - settings.startTime) / 1000)}
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.time}>
              <div className={styles.label}>Snitt tid</div>
              <div className={styles.value}>
                {averageTime
                  ? formatTime(averageTime)
                  : formatTime((settings.endTime - settings.startTime) / 1000)}
              </div>
            </div>
          </div>

          {settings.streak > 1 && (
            <div className={styles.streak}>{settings.streak} på rad!</div>
          )}

          <div className={styles.score}>
            <div className={styles.dayScore}>
              Dagens score:{' '}
              <span className={styles.points}>
                {settings.summary === true ? (
                  <CountUp
                    start={settings.oldScore}
                    end={settings.score}
                    duration={0.5}
                    delay={0}
                  />
                ) : (
                  settings.score
                )}
              </span>
            </div>
            <div className={styles.deletion}>
              Dagens score resettes om:{' '}
              <span className={styles.countdown}>
                {timeUntilMidnight > 0
                  ? timeUntilMidnight
                  : formatTime(getSecondsUntilMidnight())}
              </span>
            </div>
          </div>

          <div className={styles.buttons}>
            <Button secondary href={settings.outlet.link}>
              Les artikkelen
            </Button>
            <Button onClick={closeSummary}>Neste</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
