import React from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';

const Button = ({ href, className, secondary, small, children, ...props }) => {
  if (href) {
    return (
      <a
        {...props}
        href={href}
        target={'_blank'}
        rel={'noreferrer'}
        className={classNames(
          styles.button,
          {
            [styles.secondary]: secondary === true,
          },
          {
            [styles.small]: small === true,
          },
          className
        )}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      {...props}
      className={classNames(
        styles.button,
        {
          [styles.secondary]: secondary === true,
        },
        {
          [styles.small]: small === true,
        },
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
