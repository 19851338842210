const formatTime = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  const addZero = (num) => {
    num = Math.max(num, 0);
    var s = num + '';
    while (s.length < 2) s = '0' + s;
    return s;
  };

  let string = h > 0 ? `${addZero(h)}:` : ``;
  string += `${addZero(m)}:${addZero(s)}`;

  return string;
};

export default formatTime;
