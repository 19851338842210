import React, { useContext } from 'react';
import styles from './tutorial.module.scss';
import classNames from 'classnames';
import { SettingsContext } from '../../contexts/settings-provider';
import Button from '../button/button';
import explanation from '../../assets/tutorials.mp4';

const Tutorial = () => {
  const [settings, dispatch] = useContext(SettingsContext);

  const closeTutorial = () => {
    dispatch({
      type: 'update_settings',
      payload: { key: 'completedTutorial', value: true },
    });
  };

  return (
    <div
      className={classNames(styles.result, {
        [styles.active]: settings.completedTutorial === false,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1>Sånn fungerer spillet</h1>
          <p>
            Vi henter de ferskeste overskriftene fra 5 av Norges største aviser.
            Vi har delt opp overskriftene i enkelt ord, og lagt til noen ekstra.{' '}
            <span className={styles.highlight}>
              Sett sammen den originale overskriften for å få poeng.
            </span>{' '}
          </p>
          <video
            className={styles.video}
            src={explanation}
            autoPlay
            playsInline
            muted
            loop
          />
          <p>
            Oppgavene blir vanskeligere jo flere poeng du samler. Poengene
            resettes hvert døgn.
          </p>
        </div>
        <Button onClick={closeTutorial}>Start spillet</Button>
      </div>
    </div>
  );
};

export default Tutorial;
