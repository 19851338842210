import React, { useContext } from "react";
import { SettingsContext } from "../../contexts/settings-provider";
import styles from "./loading.module.scss";
import classNames from "classnames";
import loading from "../../assets/loader.gif";

const Loading = () => {
  const [settings] = useContext(SettingsContext);

  return (
    <div
      className={classNames(styles.loading, {
        [styles.active]: settings.loading === true,
      })}
    >
      <img className={styles.animation} src={loading} alt={"Laster"} />
      <div className={styles.status}>Laster</div>
    </div>
  );
};

export default Loading;
