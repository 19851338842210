import { useReducer, useCallback } from 'react';

const initialState = {
  loading: true,
};

const ERROR = 1;
const RESET = 2;
const VALUE = 3;

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    case RESET:
      return {
        ...state,
        value: payload.defaultValue,
      };

    case VALUE:
      return {
        ...state,
        loading: false,
        value: payload.value,
      };

    default:
      return state;
  }
};

export default (defaultValue) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    value: defaultValue,
  });

  const reset = useCallback(() => {
    dispatch({ type: RESET, payload: { defaultValue } });
  }, [defaultValue]);

  const setError = useCallback((error) => {
    dispatch({ type: ERROR, payload: { error } });
  }, []);

  const setValue = useCallback((value) => {
    dispatch({ type: VALUE, payload: { value } });
  }, []);

  return {
    error: state.error,
    loading: state.loading,
    reset,
    setError,
    setValue,
    value: state.value,
  };
};
